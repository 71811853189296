import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Product from "../components/common/product"
import Banner from "../components/common/banner"
import Cta from "../components/common/cta"
import bgImage from "../images/products/banner.png"

export const query = graphql`
  query Query($id: ID!) {
    api {
      courses(sort: "position") {
        slug
        id
        title
        acronym
        position
      }
      course(id: $id) {
        id
        slug
        title
        content
        acronym
        duration
        validity
        target_audience
        schedule
        includeds {
          title
        }
        certificate_fix {
          title
        }
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                aspectRatio: 2.3
                placeholder: BLURRED
              )
            }
          }
        }
        professionals(sort: "position") {
          slug
          title
          introduction
          description
          content
          position
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 0.8
                )
              }
            }
          }
        }
      }
      classes(where: { courses: { id: $id } }) {
        id
        price
        description
        date
        dates {
          date
        }
        courses(sort: "position") {
          id
          title
          acronym
          position
        }
        locality {
          id
          title
          city
          address
          link
        }
      }
      contract {
        download {
          url
        }
      }
    }
  }
`

const ProductPage = ({ data }) => {
  const product = data.api.course
  const classes = data.api.classes
  const courses = data.api.courses
  const download = data.api.contract.download.url

  const structuredData = {
    "@context": "https://schema.org/",
    "@type": "Offer",
    name: product.title || "",
    description: product.content?.replace(/<\/?[^>]+(>|$)/g, "") || "",
    image: {
      "@type": "ImageObject",
      url: product.image?.url || "",
    },
  }

  return (
    <Layout>
      <Seo
        title={`${product.acronym ? `${product.acronym} ` : ""}${
          product.title
        }`}
        productStructuredData={structuredData}
      />
      <Banner
        title="Cursos"
        image={bgImage}
        links={[{ title: "Cursos", path: "/cursos" }]}
      />
      <Product
        product={product}
        classes={classes}
        courses={courses}
        download={download}
      />
      <Cta />
    </Layout>
  )
}

export default ProductPage
